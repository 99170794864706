/* To support window resizing
 */

 .react-resizable {
    position: relative;
  }
  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
  }
  .react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
  }
  .react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  .react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
  }
  .react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
  }
  .react-resizable-handle-w,
  .react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }
  .react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
  }
  .react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
  }
  .react-resizable-handle-n,
  .react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }
  .react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
  }
  .react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
  }

  .reactMarkDown h1{  
    font-size: large;
  }
  .reactMarkDown h2{  
    font-size: large;
}
  
  
  /* Hide ADX admin panel in ReactApps */
  .yui-skin-sam{
    display: none !important;
  }
  /* end */
  
  ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
  }
  ::-webkit-scrollbar-track {
      border-radius: 100vh;
      background: #f7f4ed;
  }
  ::-webkit-scrollbar-thumb {
      background: #1e1e1e;
      border-radius: 100vh;
      border: 2px solid #f7f4ed;
  }
  ::-webkit-scrollbar-thumb:hover {
      background: #1e1e1e;
  }


.markdown img {
	max-width: 100%;
}

.markdown table {
	border-collapse: collapse;
	width: 100%;
}

.markdown table, th, td {
	border: 1px solid #EAEAEA;
	border-radius: 3px;
	padding: 5px;
}

.markdown tr:nth-child(even) {
	background-color: #2b2a2a;
}

/* .markdown body {
font-family: Helvetica, Arial, Freesans, clean, sans-serif;
padding:1em;
margin:auto;
max-width:42em;
background:#fefefe;
} */

.markdown h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
}

.markdown h1 {
  border-bottom: 1px solid #FDAA02;
  color: #FFF;
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 1.3rem;
}

.markdown h2 {

  border-bottom: 1px solid #FDAA02;
  color: #FFF;
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 1.3rem;
  
}

.markdown h3 {
  color: #FFF;
  font-weight: 500;
  font-size: 18px;
}

.markdown h4 {
  color: #FFF;
  font-size: 16px;
}

.markdown h5 {
  font-size: 14px;
}

.markdown h6 {
  color: #777777;
  background-color: inherit;
  font-size: 14px;
}

.markdown hr {
  height: 0.2em;
  border: 0;
  color: #CCCCCC;
  background-color: #CCCCCC;
}

.markdown p, blockquote, ul, ol, dl, li, table, pre {
  color: #FFF;
  margin: 15px 0;
}


.markdown a, .markdown a:visited {
    color: #FDAA02;
    background-color: inherit;
    text-decoration: none;
}

.markdown a:hover, .markdown a:active {
    color: #FDAA02;
    background-color: inherit;
    text-decoration: underline;
}

.markdown table, th, td {
  border: 1px solid #EAEAEA;
  border-radius: 3px;
  padding: 5px;
}

.markdown .tablelines table, .tablelines td, .tablelines th {
  border: 1px solid black; padding:10px;
  background: #777777;
  }

.markdown .tablelines tr:nth-child(even) {
  background: #777777;

}